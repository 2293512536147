<template>
  <svg width="21px" height="25px" viewBox="0 0 21 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Desktop_filtroOn" transform="translate(-203.000000, -508.000000)" fill="currentColor" fill-rule="nonzero">
        <g id="Group-9" transform="translate(148.000000, 215.000000)">
          <g id="Group-Copy-2" transform="translate(34.000000, 293.000000)">
            <g id="timer_FILL0_wght400_GRAD0_opsz48" transform="translate(21.000000, 0.000000)">
              <path d="M7,1.78784267 L7,0 L14,0 L14,1.78784267 L7,1.78784267 Z M9.625,15.1072706 L11.375,15.1072706 L11.375,8.25387366 L9.625,8.25387366 L9.625,15.1072706 Z M10.5,25 C9.06111111,25 7.70486111,24.7169249 6.43125,24.1507747 C5.15763889,23.5846246 4.04444444,22.814859 3.09166667,21.8414779 C2.13888889,20.8680969 1.38541667,19.7308304 0.83125,18.4296782 C0.277083333,17.128526 0,15.742948 0,14.272944 C0,12.80294 0.277083333,11.4173619 0.83125,10.1162098 C1.38541667,8.81505761 2.13888889,7.67779102 3.09166667,6.70441001 C4.04444444,5.731029 5.15763889,4.96126341 6.43125,4.39511323 C7.70486111,3.82896305 9.06111111,3.54588796 10.5,3.54588796 C11.8027778,3.54588796 13.0277778,3.7693683 14.175,4.21632896 C15.3222222,4.66328963 16.3430556,5.28406834 17.2375,6.07866508 L18.725,4.55899881 L19.95,5.81048868 L18.4625,7.33015495 C19.1625,8.12475169 19.7604167,9.08820024 20.25625,10.2205006 C20.7520833,11.352801 21,12.7036154 21,14.272944 C21,15.742948 20.7229167,17.128526 20.16875,18.4296782 C19.6145833,19.7308304 18.8611111,20.8680969 17.9083333,21.8414779 C16.9555556,22.814859 15.8423611,23.5846246 14.56875,24.1507747 C13.2951389,24.7169249 11.9388889,25 10.5,25 Z M10.5,23.2121573 C12.9305556,23.2121573 14.9965278,22.3430671 16.6979167,20.6048868 C18.3993056,18.8667064 19.25,16.7560588 19.25,14.272944 C19.25,11.7898292 18.3993056,9.67918157 16.6979167,7.94100119 C14.9965278,6.20282082 12.9305556,5.33373063 10.5,5.33373063 C8.06944444,5.33373063 6.00347222,6.20282082 4.30208333,7.94100119 C2.60069444,9.67918157 1.75,11.7898292 1.75,14.272944 C1.75,16.7560588 2.60069444,18.8667064 4.30208333,20.6048868 C6.00347222,22.3430671 8.06944444,23.2121573 10.5,23.2121573 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>