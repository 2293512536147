
import {
  ARTICLE_PARAGRAPH,
  CALL_TO_ACTION,
  PICTURE,
  TABLE,
  FOOTNOTE
} from '@/utils/constants'

const wpm = 180;

const DATA_TO_READ = {
  [ARTICLE_PARAGRAPH]: [ 'title', 'body'],
  [CALL_TO_ACTION]: ['body', 'buttonText'],
  [PICTURE]: ['caption'],
  [TABLE]: ['html'],
  [FOOTNOTE]: ['body']
}

export function getReadingTime(article) {
  const text = collectText(article)
  const words = text.trim().split(/\s+/).length;
  return Math.ceil(words / wpm);
}

function collectText(article) {
  return article.reduce(
    (accumulator, component) => {
      const currentValue = DATA_TO_READ[component.__typename].reduce(
        (total, param) => total + component[param], ''
      )
      return accumulator + currentValue
    }, '' )
}